import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {CountProportionsPlotChoice, Elongatedness, Viability} from "./CountProportionsPlotChoice";
import {CountPlot} from "./CountPlot";
import {PlotlyStylePlot} from "../PlotlyStylePlot";
import {CountProportionsLegend} from "./CountProportionsLegend";

export type CountProportionsPlotType = {
    index: number,
    daSubPlots: Array<PlotlyStylePlot<CountProportionsLegend>>,
    tankName: string,
    location: string,
    forDashboard: boolean,
    plotName: string,
}


//Needs to be single element, so we can get the tank and location name off it
export const CreateNormalPlot: FC<CountProportionsPlotType> = props => {
    const {index, daSubPlots, tankName, location, forDashboard, plotName} = props;
    const colors = [
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-1'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-5'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-4'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-3')
    ]

    const myData: Plotly.Data[] = daSubPlots.sort((l, r) => {
        let left = CountProportionsLegend[l.legend];
        let right = CountProportionsLegend[r.legend];
        return Number(left) - Number(right)
    }).map((proportionPlot, plotIndex) => {
        return {
            type: 'bar',
            x: proportionPlot.x.map(o => new Date(o.iMillis)),
            y: proportionPlot.y,
            mode: 'lines+markers',
            marker: {
                color: colors[plotIndex]
            },
            name: proportionPlot.legend.toString()
        }
    })
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: 'Count Proportions ' + plotName + ' for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Cell Type Proportions'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}

function choosePlot(plotChoice: CountProportionsPlotChoice): (CountPlot) => {
    plot: Array<PlotlyStylePlot<CountProportionsLegend>>,
    plotName: string
} {

    let emptyPlots = new Array<PlotlyStylePlot<CountProportionsLegend>>();
    let emptyAnswer = {plot: emptyPlots, plotName: ""}
    if (!plotChoice.elongatedness || !plotChoice.viability) {
        return () => emptyAnswer
    }

    switch (plotChoice.elongatedness) {
        case Elongatedness.Elongated:
            switch (plotChoice.viability) {
                case Viability.Alive:
                    return p => ({plot: p.elongatedAlivePlot, plotName: "Elongated Alive"})
                case Viability.Dead:
                    return p => ({plot: p.elongatedDeadPlot, plotName: "Elongated Dead"})
            }
            break
        case Elongatedness.Starter:
            switch (plotChoice.viability) {
                case Viability.Alive:
                    return p => ({plot: p.starterAlivePlot, plotName: "Starter Alive"})
                case Viability.Dead:
                    return p => ({plot: p.starterDeadPlot, plotName: "Starter Dead"})
            }
    }

    return () => emptyAnswer
}

export const ShowCountProportionsPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: CountPlot,
    plotChoice: CountProportionsPlotChoice
}> = (props) => {

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    let pickedPlot = plotPicker(plots);
    return <div className="plot-list">
        {plots && pickedPlot && pickedPlot.plot && pickedPlot.plot.length > 1 ?
            <CreateNormalPlot index={0} daSubPlots={pickedPlot.plot} plotName={pickedPlot.plotName}
                              tankName={tankName ?? "unknown tank"}
                              location={locationName ?? "unknown location"} forDashboard={false}/> : null}
    </div>;
}
// in profile.js
import React, {FC, useCallback, useMemo, useState} from "react";
import {
    DateTimeInput,
    Error,
    FormDataConsumer,
    Loading,
    RaRecord,
    required,
    SaveContextProvider,
    SelectInput,
    SimpleForm,
    Title,
    useDataProvider,
    useNotify,
    useRecordContext
} from "react-admin";
import {useGetList} from "ra-core";
import {TankInput} from "../../Tanks/tankInput";

//stylesheet
import "../custom.css"
import {ElongatedRatioPlot} from "./ElongatedRatioPlot";
import {ElongatedRatiosPlotChoice} from "./ElongatedRatiosPlotChoice";
import {ShowElongatedRatiosPlots} from "./ShowElongatedRatiosPlots";


type PartialPlotPageType = { save: (values: any) => void, now?: string, plotChoice: ElongatedRatiosPlotChoice, locations: RaRecord[] };

const ElongatedRatios: FC<PartialPlotPageType> = (props) => {
    const record = useRecordContext();
    return <SimpleForm onSubmit={props.save} record={record}>
        <DateTimeInput source={"from"}
                       validate={required()} label={"From"}/>
        <DateTimeInput source={"to"}
                       validate={required()} label={"To"}
                       defaultValue={props.now}/>
        <SelectInput source={"location"} choices={Object.values(props.locations)}
                     validate={required()}/>
        <SelectInput source={"elongatedRatio"} label={"Ratio"}
                     choices={[
                         {id: 'Elongated', name: 'Elongated'},
                         {id: 'Starter', name: 'Starter'}
                     ]} validate={required()}/>
        <FormDataConsumer subscription={{values: true}}>
            {({formData, ...rest}) => {
                if (formData.location) {
                    return <TankInput source="tank" {...rest}
                                      link={formData.location}
                                      validate={required()}/>
                } else {
                    return null;
                }
            }}
        </FormDataConsumer>
    </SimpleForm>;
}


function translateDatesToISOString(values: {
    from?: Date,
    to?: string,
}) {
    let from = values.from ? values.from.toISOString() : null;
    let to = values.to ? new Date(values.to).toISOString() : null;
    return {
        ...values,
        from: from,
        to: to,
    }
}

type PlotType = {
    dataFound: boolean,
    tankName?: string,
    locationName?: string,
    plots?: ElongatedRatioPlot,
}

export const ElongatedRatiosPlotPage: FC = (props) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    const [plotChoice, setPlotChoice] = useState<ElongatedRatiosPlotChoice>({elongatedRatio: null});
    const [plotValues, setPlots] = useState<PlotType>({dataFound: false})

    const handleSave = useCallback((values) => {
        setSaving(true);
        setPlotChoice({elongatedRatio: values.elongatedRatio})
        let retrievePlotsAsync: Promise<{ data: PlotType }> = dataProvider.retrievePlots(
            {data: {type: 'elongatedratio', ...translateDatesToISOString(values)}});
        retrievePlotsAsync.then(i => {
            setSaving(false);
            setPlots(i.data)
            if (i.data.dataFound) {
                notify("Plots have been retrieved");
            } else {
                notify("Plots retrieval finished, no data found");
            }
        }).catch(_f => {
            setSaving(false);
            notify(
                "A technical error occurred while retrieving plots. Please try later."
            );
        })
    }, [dataProvider, notify]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    const [currentTimeStamp] = useState(new Date());

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}
                                      resetErrorBoundary={() => {
                                          return null;
                                      }}/>;
    return (
        <div>
            <SaveContextProvider value={saveContext}>
                <React.Fragment>
                    <Title {...props} title="Elongated Ratios"/>
                    <ElongatedRatios
                        save={handleSave}
                        plotChoice={plotChoice}
                        locations={locations}
                        now={currentTimeStamp.toISOString()}/>
                </React.Fragment>
            </SaveContextProvider>

            {!saving ?
                <ShowElongatedRatiosPlots tankName={plotValues.tankName}
                                           locationName={plotValues.locationName}
                                           plots={plotValues.plots}
                                           plotChoice={plotChoice}/>
                : null}
        </div>


    );
};

// "use strict";
import {CreateParams, DataProvider, fetchUtils, HttpError, RaRecord} from 'react-admin';

import jsonServerProvider from "ra-data-json-server";
import {AccountInfo} from "@azure/msal-common/dist/account/AccountInfo";
import {createHttpClient} from "./create";
import {IPublicClientApplication} from "@azure/msal-browser";
import {CreateResult} from "ra-core/src/types";

export const backendUrl = process.env.REACT_APP_ADMIN_SERVER;


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64: (file: { rawFile: File }) => Promise<EncodedFile> = (file: { rawFile: File; }) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve({result: reader.result, name: file.rawFile.name});
        reader.onerror = reject;
    });

export type EncodedFile = { result: string | ArrayBuffer, name: string };

export type CookParams = {
    from?: Date,
    to?: string,
    from1?: Date,
    to1?: string,
    from2?: Date,
    to2?: string,
    point?: Date
};

export type PlotType = {
    dataFound: boolean,
    tankName?: string,
    locationName?: string,
    location?: string,
    tankType?: string,
    timepoint?: { iMillis: number },
    locationUpper?: string,
    locationBottom?: string,
    tankNameUpper?: string,
    tankNameBottom?: string
    comparisonMode?: string,

}
type T = <RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: CreateParams
) => Promise<CreateResult<RecordType>>

type CreateFunctionType = (d: DataProvider) => T


const overriddenCreateFunction: CreateFunctionType = (dataProvider: DataProvider) => (resource: string, params: CreateParams) => {
    if (resource !== 'experiments') {
        // fallback to the default implementation
        return dataProvider.create(resource, params);
    }

    let firstBatch = params.data.files1.filter((p: { rawFile: any; }) => p.rawFile.name.endsWith("jpg") || p.rawFile.name.endsWith("png") || p.rawFile.name.endsWith("jpeg"));
    if (firstBatch.length !== 16) {
        return Promise.reject(new HttpError(
            "Need exactly 16 pictures in first folder",
            404
        ));
    }
    let secondBatch = params.data.files2.filter((p: { rawFile: any; }) => p.rawFile.name.endsWith("jpg") || p.rawFile.name.endsWith("png") || p.rawFile.name.endsWith("jpeg"));
    if (secondBatch.length !== 16) {
        return Promise.reject(new HttpError(
            "Need exactly 16 pictures in second folder",
            404
        ));
    }


    const files1 = firstBatch.filter(
        (p: { rawFile: any; }) => p.rawFile instanceof File
    );
    const files2 = secondBatch.filter(
        (p: { rawFile: any; }) => p.rawFile instanceof File
    );


    let convertedFiles1: EncodedFile[] = files1.map(convertFileToBase64);
    let convertedFiles2: EncodedFile[] = files2.map(convertFileToBase64);
    let firstConversion = Promise.all(convertedFiles1)
        .then(base64Pictures =>
            base64Pictures.map(picture64 => ({
                name: picture64.name,
                src: picture64.result,
            }))
        );
    let secondConversion = Promise.all(convertedFiles2)
        .then(base64Pictures =>
            base64Pictures.map(picture64 => ({
                name: picture64.name,
                src: picture64.result,
            }))
        );
    return firstConversion
        .then(transformedFirstBatchPictures =>
            secondConversion.then(transformedSecondBatchPictures => {
                    return dataProvider.create(resource, {
                        ...params,
                        data: {
                            ...params.data,
                            pictures1: [
                                ...transformedFirstBatchPictures
                            ],
                            pictures2: [
                                ...transformedSecondBatchPictures
                            ],

                        },
                    })
                }
            ));
}

export const createDecoratedDataProvider = (msalAuthProviderInstance: IPublicClientApplication, msalAuthProviderAccounts: Array<AccountInfo>) => {
    let httpClient = createHttpClient(msalAuthProviderInstance, msalAuthProviderAccounts);
    const dataProvider = jsonServerProvider(backendUrl, httpClient);
    return {
        ...dataProvider,
        async retrievePlots(arg: {
            data: {
                type: string,
                from?: Date,
                to?: string,
                from1?: Date,
                to1?: string,
                from2?: Date,
                to2?: string,
                point?: Date,
                mode?: string,
                tank?: string,
                tank1?: string,
                tank2?: string,
                location?: string,
                tankType?: string
            }
        }): Promise<{ data: PlotType }> {
            const data = arg.data;
            const resource = 'plots';
            const url = `${backendUrl}/${resource}?`;
            let typedUrl = new URL(url);
            // TODO do this better
            typedUrl.searchParams.append("type", data.type);
            if (data.from) {
                typedUrl.searchParams.append("from", data.from.toString());
            }
            if (data.point) {
                typedUrl.searchParams.append("point", data.point.toString());
            }
            if (data.to) {
                typedUrl.searchParams.append("to", data.to.toString());
            }
            if (data.from1) {
                typedUrl.searchParams.append("from1", data.from1.toString());
            }
            if (data.from2) {
                typedUrl.searchParams.append("from2", data.from2.toString());
            }
            if (data.to1) {
                typedUrl.searchParams.append("to1", data.to1.toString());
            }
            if (data.to2) {
                typedUrl.searchParams.append("to2", data.to2.toString());
            }
            if (data.mode) {
                typedUrl.searchParams.append("mode", data.mode);
            }
            if (data.tank) {
                typedUrl.searchParams.append("tank", data.tank);
            }
            if (data.tank1) {
                typedUrl.searchParams.append("tank1", data.tank1);
            }
            if (data.tank2) {
                typedUrl.searchParams.append("tank2", data.tank2);
            }
            if (data.location) {
                typedUrl.searchParams.append("location", data.location);
            }
            if (data.tankType) {
                typedUrl.searchParams.append("tankType", data.tankType);
            }

            return httpClient(typedUrl.href).then((p: { json: PlotType }) => ({data: p.json}));
        },
        create: overriddenCreateFunction(dataProvider),
    };
}

export const createLocalDecoratedDataProvider = () => {
    const dataProvider = jsonServerProvider(backendUrl);
    return {
        ...dataProvider,
        async retrievePlots(arg: {
            data: {
                type: string,
                from?: Date,
                to?: string,
                from1?: Date,
                to1?: string,
                from2?: Date,
                to2?: string,
                point?: Date,
                mode?: string,
                tank?: string,
                tank1?: string,
                tank2?: string,
                location?: string,
                tankType?: string
            }
        }): Promise<{ data: PlotType }> {
            const data = arg.data;
            console.log("Data is ")
            console.log(data);
            const resource = 'plots';
            const url = `${backendUrl}/${resource}?`;
            let typedUrl = new URL(url);
            // This can be a little better
            typedUrl.searchParams.append("type", data.type);
            if (data.from) {
                typedUrl.searchParams.append("from", data.from.toString());
            }
            if (data.point) {
                typedUrl.searchParams.append("point", data.point.toString());
            }
            if (data.to) {
                typedUrl.searchParams.append("to", data.to.toString());
            }
            if (data.from1) {
                typedUrl.searchParams.append("from1", data.from1.toString());
            }
            if (data.from2) {
                typedUrl.searchParams.append("from2", data.from2.toString());
            }
            if (data.to1) {
                typedUrl.searchParams.append("to1", data.to1.toString());
            }
            if (data.to2) {
                typedUrl.searchParams.append("to2", data.to2.toString());
            }
            if (data.mode) {
                typedUrl.searchParams.append("mode", data.mode);
            }
            if (data.tank) {
                typedUrl.searchParams.append("tank", data.tank);
            }
            if (data.tank1) {
                typedUrl.searchParams.append("tank1", data.tank1);
            }
            if (data.tank2) {
                typedUrl.searchParams.append("tank2", data.tank2);
            }
            if (data.location) {
                typedUrl.searchParams.append("location", data.location);
            }
            if (data.tankType) {
                typedUrl.searchParams.append("tankType", data.tankType);
            }

            return fetchUtils.fetchJson(typedUrl.href).then((p: { json: PlotType }) => ({data: p.json}))
        },
        create: overriddenCreateFunction(dataProvider),
    };
}

export default createDecoratedDataProvider;


import React, { FC, useEffect} from "react";
import {Error, Labeled, NumberInput, required, SelectInput} from "react-admin";
import {TankInput} from "../Tanks/tankInput";
import {validateCustomDays} from "../Common/Validators";
import {ConfigType} from "./configType";
import {elongatedChoices, viabilityChoices} from "./choices";


export const DashboardCountConfig:FC<ConfigType> =
    (props) => {
    const {timeChoices, setDisableAdd, disableAdd, formData, scopedFormData, getSource} = props;
    useEffect(() => {
            if (formData && formData.plots && formData.plots.length >= 4) {
                setDisableAdd(true)
            } else {
                setDisableAdd(false)
            }
        },
        [disableAdd, formData, setDisableAdd]);
    if(!getSource){
        return <Error error={{name: "Unexpected exception", message: "getSource function passed in is undefined"}}  resetErrorBoundary={() => {return null;}}/>
    }
    return (
        <React.Fragment>
            <div className={"plot-handle__wrapper"}>
                <Labeled label={"Visualize"}
                         className={"plot-handle__label-parent"}><span/></Labeled>
                <SelectInput source={getSource("time")} choices={timeChoices}
                             label={"Choose time type"}/>
                {scopedFormData.time && scopedFormData.time === "custom" ?
                    <NumberInput source={getSource("days")}
                                 label={"Custom number of days"}
                                 step={1}
                                 validate={validateCustomDays}
                    />
                    : null}
                {formData.id ?
                    <TankInput source={getSource("tank")}
                               label={"tank"}
                               link={formData.id}
                               validate={required()}
                    />
                    : null}
                <SelectInput source={getSource("elongated")} choices={elongatedChoices}
                             label={"Elongated"} validate={required()}/>
                <SelectInput source={getSource("viability")} choices={viabilityChoices}
                             label={"Viability"} validate={required()}/>
            </div>
        </React.Fragment>
    )
}
import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {MeanAndCi, MeanAndCiPlotChoice} from "./MeanAndCiPlotChoice";
import {MeanAndCiPlot} from "./MeanAndCiPlot";
import {PlotlyStylePlot} from "../PlotlyStylePlot";
import {MeanAndCiLegend} from "./MeanAndCiLegend";
import {MeanAndCiPlotType} from "../MeanAndCiPlotType";

function createTitles(legend: MeanAndCiLegend): { meanTitle: string, percentileTitle: string, graphTitle: string } {
    switch (legend) {
        case MeanAndCiLegend.ElongatedBuddingAlive:
            return {
                meanTitle: "Elongated Budding Alive Mean",
                percentileTitle: "Elongated Budding Alive Percentile",
                graphTitle: "Elongated Budding Alive"
            }
        case MeanAndCiLegend.ElongatedBuddingDead:
            return {
                meanTitle: "Elongated Budding Dead Mean",
                percentileTitle: "Elongated Budding Dead Percentile",
                graphTitle: "Elongated Budding Dead"
            }
        case MeanAndCiLegend.ElongatedSingleAlive:
            return {
                meanTitle: "Elongated Single Alive Mean",
                percentileTitle: "Elongated Single Alive Percentile",
                graphTitle: "Elongated Single Alive"
            }
        case MeanAndCiLegend.ElongatedSingleDead:
            return {
                meanTitle: "Elongated Single Dead Mean",
                percentileTitle: "Elongated Single Dead Percentile",
                graphTitle: "Elongated Single Dead"
            }
        case MeanAndCiLegend.ElongatedClumpingAlive:
            return {
                meanTitle: "Elongated Clumping Alive Mean",
                percentileTitle: "Elongated Clumping Alive Percentile",
                graphTitle: "Elongated Clumping Alive"
            }
        case MeanAndCiLegend.ElongatedClumpingDead:
            return {
                meanTitle: "Elongated Clumping Dead Mean",
                percentileTitle: "Elongated Clumping Dead Percentile",
                graphTitle: "Elongated Clumping Dead"
            }
        case MeanAndCiLegend.ElongatedDividingAlive:
            return {
                meanTitle: "Elongated Dividing Alive Mean",
                percentileTitle: "Elongated Dividing Alive Percentile",
                graphTitle: "Elongated Dividing Alive"
            }
        case MeanAndCiLegend.ElongatedDividingDead:
            return {
                meanTitle: "Elongated Dividing Dead Mean",
                percentileTitle: "Elongated Dividing Dead Percentile",
                graphTitle: "Elongated Dividing Dead"
            }
        case MeanAndCiLegend.StarterBuddingAlive:
            return {
                meanTitle: "Starter Budding Alive Mean",
                percentileTitle: "Starter Budding Alive Percentile",
                graphTitle: "Starter Budding Alive"
            }
        case MeanAndCiLegend.StarterBuddingDead:
            return {
                meanTitle: "Starter Budding Dead Mean",
                percentileTitle: "Starter Budding Dead Percentile",
                graphTitle: "Starter Budding Dead"
            }
        case MeanAndCiLegend.StarterSingleAlive:
            return {
                meanTitle: "Starter Single Alive Mean",
                percentileTitle: "Starter Single Alive Percentile",
                graphTitle: "Starter Single Alive"
            }
        case MeanAndCiLegend.StarterSingleDead:
            return {
                meanTitle: "Starter Single Dead Mean",
                percentileTitle: "Starter Single Dead Percentile",
                graphTitle: "Starter Single Dead"
            }
        case MeanAndCiLegend.StarterClumpingAlive:
            return {
                meanTitle: "Starter Clumping Alive Mean",
                percentileTitle: "Starter Clumping Alive Percentile",
                graphTitle: "Starter Clumping Alive"
            }
        case MeanAndCiLegend.StarterClumpingDead:
            return {
                meanTitle: "Starter Clumping Dead Mean",
                percentileTitle: "Starter Clumping Dead Percentile",
                graphTitle: "Starter Clumping Dead"
            }
        case MeanAndCiLegend.StarterDividingAlive:
            return {
                meanTitle: "Starter Dividing Alive Mean",
                percentileTitle: "Starter Dividing Alive Percentile",
                graphTitle: "Starter Dividing Alive"
            }
        case MeanAndCiLegend.StarterDividingDead:
            return {
                meanTitle: "Starter Dividing Dead Mean",
                percentileTitle: "Starter Dividing Dead Percentile",
                graphTitle: "Starter Dividing Dead"
            }
        case MeanAndCiLegend.ElongatedAlive:
            return {
                meanTitle: "Elongated Alive Mean",
                percentileTitle: "Elongated Alive Percentile",
                graphTitle: "Elongated Alive"
            }
        case MeanAndCiLegend.ElongatedDead:
            return {
                meanTitle: "Elongated Dead Mean",
                percentileTitle: "Elongated Dead Percentile",
                graphTitle: "Elongated Dead"
            }
        case MeanAndCiLegend.StarterAlive:
            return {
                meanTitle: "Starter Alive Mean",
                percentileTitle: "Starter Alive Percentile",
                graphTitle: "Starter Alive"
            }
        case MeanAndCiLegend.StarterDead:
            return {
                meanTitle: "Starter Dead Mean",
                percentileTitle: "Starter Dead Percentile",
                graphTitle: "Starter Dead"
            }

    }
}

export const CreateMeanAndCiPlot: FC<MeanAndCiPlotType<MeanAndCiLegend>> = props => {
    const {index, meanAndCiPlot, tankName, location, forDashboard} = props;
    const meanColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph');
    const percentileColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph-lighter');

    let titles = createTitles(meanAndCiPlot.mean.legend);
    const myData: Plotly.Data[] = [
        {
            type: 'scatter',
            x: meanAndCiPlot.mean.x.map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.mean.y,
            mode: 'lines',
            line: {color: meanColor},
            name: titles.meanTitle
        },
        {
            type: 'scatter',
            x: (meanAndCiPlot.percentile.x).map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.percentile.y,
            fill: 'toself',
            line: {color: percentileColor},
            hoverinfo: 'skip',
            name: titles.percentileTitle
        },
    ];
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: titles.graphTitle + ' for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Yeast cells per mL'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}


function choosePlot(plotChoice: MeanAndCiPlotChoice): (MeanAndCiPlot) => {
    mean:  PlotlyStylePlot<MeanAndCiLegend>,
    percentile:  PlotlyStylePlot<MeanAndCiLegend>
} {

    if (!plotChoice.meanAndCi) {
        return null
    }

    switch (plotChoice.meanAndCi) {
        case MeanAndCi.ElongatedBuddingAlive:
            return p => p.elongatedBuddingAlivePlot
        case MeanAndCi.ElongatedBuddingDead:
            return p => p.elongatedBuddingDeadPlot
        case MeanAndCi.ElongatedSingleAlive:
            return p => p.elongatedSingleAlivePlot
        case MeanAndCi.ElongatedSingleDead:
            return p => p.elongatedSingleDeadPlot
        case MeanAndCi.ElongatedClumpingAlive:
            return p => p.elongatedClumpingAlivePlot
        case MeanAndCi.ElongatedClumpingDead:
            return p => p.elongatedClumpingDeadPlot
        case MeanAndCi.ElongatedDividingAlive:
            return p => p.elongatedDividingAlivePlot
        case MeanAndCi.ElongatedDividingDead:
            return p => p.elongatedDividingDeadPlot
        case MeanAndCi.StarterBuddingAlive:
            return p => p.starterBuddingAlivePlot
        case MeanAndCi.StarterBuddingDead:
            return p => p.starterBuddingDeadPlot
        case MeanAndCi.StarterSingleAlive:
            return p => p.starterSingleAlivePlot
        case MeanAndCi.StarterSingleDead:
            return p => p.starterSingleDeadPlot
        case MeanAndCi.StarterClumpingAlive:
            return p => p.starterClumpingAlivePlot
        case MeanAndCi.StarterClumpingDead:
            return p => p.starterClumpingDeadPlot
        case MeanAndCi.StarterDividingAlive:
            return p => p.starterDividingAlivePlot
        case MeanAndCi.StarterDividingDead:
            return p => p.starterDividingDeadPlot
        case MeanAndCi.ElongatedAlive:
            return p => p.elongatedAlivePlot
        case MeanAndCi.ElongatedDead:
            return p => p.elongatedDeadPlot
        case MeanAndCi.StarterAlive:
            return p => p.starterAlivePlot
        case MeanAndCi.StarterDead:
            return p => p.starterDeadPlot

    }

    return null
}

export const ShowMeanAndCiPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: MeanAndCiPlot,
    plotChoice: MeanAndCiPlotChoice
}> = (props) => {
    console.log("Showing elongated ratios plots " + props.plots)

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    return <div className="plot-list">
        {plots && plotPicker(plots) ?
            <CreateMeanAndCiPlot index={0} meanAndCiPlot={plotPicker(plots)} tankName={tankName ?? "unknown tank"}
                                 location={locationName ?? "unknown location"} forDashboard={false}/> : null}
    </div>;
}
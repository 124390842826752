import {useGetList} from "ra-core";
import {
    Error,
    Labeled,
    Loading,
    SelectField,
    Show,
    ShowProps,
    SimpleShowLayout,
    useRecordContext
} from "react-admin";
import React, {FC} from "react";

export const ThresholdsShow = (props:ShowProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    return (
        <Show title="Thresholds detail" {...props}>
            <SimpleShowLayout>
                <SelectField source={"id"} label={"Location"} choices={Object.values(locations)}/>
                <ThresholdsFieldWithLabel source="total" label={"Total"} unit={"M cells/mL"}/>
                <ThresholdsFieldWithLabel source="single" label={"Single"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="budding" label={"Budding"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="dividing" label={"Dividing"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="clumping" label={"Clumping"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="viability" label={"Viability"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="elongatedRatio" label={"Elongated Alive/Total Alive cells"} unit={"%"}/>
                <ThresholdsFieldWithLabel source="totalAliveStarter" label={"Total Alive Starter"} unit={"M cells/mL"}/>
            </SimpleShowLayout>
        </Show>
    );
}

const ThresholdsFieldWithLabel:FC<{source:string, unit:string, label:string}> = props => {
    const record = useRecordContext();
    const {source, unit, label} = props;
    const amountKey = source + 'Amount'
    const typeKey = source + 'Type'
    const amount = record[amountKey]
    const type = record[typeKey]

    if (amount === undefined || type === undefined) {
        return (<Labeled label={label}><span>No value selected</span></Labeled>);
    } else {
        return (
            <Labeled label={label}><span>{`Warn if ${type.toLowerCase()} ${amount} ${unit}`}</span></Labeled>);
    }
}


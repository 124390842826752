import React, {FC} from "react";
import {useRecordContext} from "react-admin";



function showByCssClass(classname:string) {
    [].forEach.call(document.querySelectorAll('.' + classname), function (el) {
        // @ts-ignore
        el.classList.toggle('hidden');
    });
}

export const PngActions:FC = (_) => {
    const record = useRecordContext();
    if (record && record.status === 'BUSY') {
        return <div>Awaiting completion of experiments, no cells recognized yet</div>
    } else {
        return (
            <div className={"button-menu"}>
                <label className={"toggle-switch-alivestarter"}>
                    <input type={"checkbox"}
                           className="toggle-switch-alivestarter__input chart-button--alivestarter"
                           onClick={() => showByCssClass('alivestarter')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-alivestarter__slider"></span>
                    <span className="toggle-switch-alivestarter__label">Alive/Starter</span>
                </label>
                <label className={"toggle-switch-aliveelongated"}>
                    <input type={"checkbox"}
                           className="toggle-switch-aliveelongated__input chart-button--aliveelongated"
                           onClick={() => showByCssClass('aliveelongated')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-aliveelongated__slider"></span>
                    <span className="toggle-switch-aliveelongated__label">Alive/Elongated</span>
                </label>

                <label className={"toggle-switch-deadstarter"}>
                    <input type={"checkbox"}
                           className="toggle-switch-deadstarter__input chart-button--deadstarter"
                           onClick={() => showByCssClass('deadstarter')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-deadstarter__slider"></span>
                    <span className="toggle-switch-deadstarter__label">Dead/Starter</span>
                </label>

                <label className={"toggle-switch-deadelongated"}>
                    <input type={"checkbox"}
                           className="toggle-switch-deadelongated__input chart-button--deadelongated"
                           onClick={() => showByCssClass('deadelongated')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-deadelongated__slider"></span>
                    <span className="toggle-switch-deadelongated__label">Dead/Elongated</span>
                </label>

            </div>

        );
    }
}

import {useGetList} from "ra-core";
import {
    Datagrid,
    Error,
    FunctionField,
    List,
    ListProps,
    RaRecord,
    Loading,
    SelectField,
    useRecordContext
} from "react-admin";
import React, {FC} from "react";

const ThresholdsField :FC<{unit:string, source:string, label:string}> = props => {
    const record = useRecordContext()
    const {source, unit} = props;
    const amountKey = source + 'Amount'
    const typeKey = source + 'Type'
    const amount = record[amountKey]
    const type = record[typeKey]

    if (amount === undefined || type === undefined) {
        return (<FunctionField render={ (_record?: RaRecord , _source?: string)  => "No value selected"} {...props}/>);
    } else {
        return <FunctionField
            render={(_record?: RaRecord , _source?: string) => `Warn if ${type.toLowerCase()} ${amount} ${unit}`}  {...props} />;
    }
}

export const ThresholdsList = (props : ListProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    return (
        <List {...props} exporter={false}>
            <Datagrid rowClick="edit">
                <SelectField source={"id"} label={"Location"} choices={Object.values(locations)}/>
                <ThresholdsField source="total" label={"Total"} unit={"M cells/mL"}/>
                <ThresholdsField source="single" label={"Single"} unit={"%"}/>
                <ThresholdsField source="budding" label={"Budding"} unit={"%"}/>
                <ThresholdsField source="dividing" label={"Dividing"} unit={"%"}/>
                <ThresholdsField source="clumping" label={"Clumping"} unit={"%"}/>
                <ThresholdsField source="viability" label={"Viability"} unit={"%"}/>
                <ThresholdsField source="elongatedRatio" label={"Elongated Alive/Total Alive cells"} unit={"%"}/>
                <ThresholdsField source="totalAliveStarter" label={"Total Alive Starter"} unit={"M cells/mL"}/>
            </Datagrid>
        </List>
    );
}
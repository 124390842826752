import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {ElongatedRatioPlot} from "./ElongatedRatioPlot";
import {ElongatedRatio, ElongatedRatiosPlotChoice} from "./ElongatedRatiosPlotChoice";
import {ElongatedRatioLegend} from "./ElongatedRatioLegend";
import {MeanAndCiPlotType} from "../MeanAndCiPlotType";



function createTitles(legend: ElongatedRatioLegend): {
    meanTitle: string,
    percentileTitle: string,
    graphTitle: string
} {
    switch (legend) {
        case ElongatedRatioLegend.StarterRatio:
            return {
                meanTitle: "Alive Starter Ratio Mean",
                percentileTitle: "Alive Starter Ratio Percentile",
                graphTitle: "Alive Starter Ratio"
            }
        case ElongatedRatioLegend.ElongatedRatio:
            return {
                meanTitle: "Alive Elongated Ratio Mean",
                percentileTitle: "Alive Elongated Ratio Percentile",
                graphTitle: " Alive Elongated Ratio"
            }
    }
}

export const CreateElongatedRatioPlot: FC<MeanAndCiPlotType<ElongatedRatioLegend>> = props => {
    const {index, meanAndCiPlot, tankName, location, forDashboard} = props;
    const meanColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph');
    const percentileColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph-lighter');

    let plotTitles = createTitles(meanAndCiPlot.mean.legend);
    const myData: Plotly.Data[] = [
        {
            type: 'scatter',
            x: meanAndCiPlot.mean.x.map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.mean.y,
            mode: 'lines',
            line: {color: meanColor},
            name: plotTitles.meanTitle
        },
        {
            type: 'scatter',
            x: (meanAndCiPlot.percentile.x).map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.percentile.y,
            fill: 'toself',
            line: {color: percentileColor},
            hoverinfo: 'skip',
            name: plotTitles.percentileTitle
        },
    ];
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: plotTitles.graphTitle + ' for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: `${plotTitles.graphTitle}`
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}


export type ElongatedRatioProportionPlotType = {
    legend: ElongatedRatioLegend,
    x: Array<{ iMillis: number }>,
    y: Float32Array
};

function choosePlot(plotChoice: ElongatedRatiosPlotChoice): (ElongatedRatioPlot) => {
    mean: ElongatedRatioProportionPlotType,
    percentile: ElongatedRatioProportionPlotType
} {

    if (!plotChoice.elongatedRatio) {
        return null
    }

    switch (plotChoice.elongatedRatio) {
        case ElongatedRatio.Elongated:
            return p => {
                return p.elongatedPlot
            }
        case ElongatedRatio.Starter:
            return p => p.starterPlot
    }

    return null
}

export const ShowElongatedRatiosPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: ElongatedRatioPlot,
    plotChoice: ElongatedRatiosPlotChoice
}> = (props) => {

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    return <div className="plot-list">
        {plots && plotPicker(plots) ?
            <CreateElongatedRatioPlot index={0} meanAndCiPlot={plotPicker(plots)} tankName={tankName ?? "unknown tank"}
                                      location={locationName ?? "unknown location"}
                                      forDashboard={false}/> : null}
    </div>;
}
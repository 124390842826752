import {useGetList} from "ra-core";
import {Error, Labeled, Loading, SelectField, Show, ShowProps, SimpleShowLayout, useRecordContext} from "react-admin";
import React, {FC} from "react";
import {FakeFieldToTrickLabelToDisplay, TimeField} from "./timeField";
import {elongatedChoices, morphologyChoices, viabilityChoices} from "./choices";


export const CountPlot: FC<{index:number}> = props => {
    const record = useRecordContext();
    const realRecord = record.dashboards[props.index];
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', { pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}, filter:{filter: record.link}});

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}  resetErrorBoundary={() => {return null;}}/>;

    return (<React.Fragment>
        <Labeled label="Count Plot" className={"dashboard-show__label-plot-name"}><FakeFieldToTrickLabelToDisplay source={"not empty"}/></Labeled>
        <Labeled label={"Tank"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"tank"}
                         label={"Tank"}
                         choices={Object.values(tanks)}
            record={realRecord}/>
        </Labeled>
        <Labeled label={"Elongated"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"elongated"}
                         label={"Elongated"}
                         choices={Object.values(elongatedChoices)}
                         record={realRecord}/>
        </Labeled>
        <Labeled label={"Viability"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"viability"}
                         label={"Viability"}
                         choices={Object.values(viabilityChoices)}
                         record={realRecord}/>
        </Labeled>
        <TimeField source={"time"} source2={"days"} index={props.index}/>

    </React.Fragment>);
}

export const ElongatedRatioPlot: FC<{index:number}> = props => {
    const record = useRecordContext();
    const realRecord = record.dashboards[props.index];
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', { pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}, filter:{filter: record.link}});

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}  resetErrorBoundary={() => {return null;}}/>;

    return (<React.Fragment>
        <Labeled label="Elongated Ratio Plot" className={"dashboard-show__label-plot-name"}><FakeFieldToTrickLabelToDisplay source={"not empty"}/></Labeled>
        <Labeled label={"Tank"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"tank"}
                         label={"Tank"}
                         choices={Object.values(tanks)}
            record={realRecord}/>
        </Labeled>
        <Labeled label={"Elongated"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"elongated"}
                         label={"Elongated"}
                         choices={Object.values(elongatedChoices)}
                         record={realRecord}/>
        </Labeled>
        <TimeField source={"time"} source2={"days"} index={props.index}/>

    </React.Fragment>);
}


export const MeanAndCiPlot:FC<{index:number}> = props => {
    const record = useRecordContext();
    const realRecord = record.dashboards[props.index];
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', {pagination:{
        page: 1,
        perPage: 1000
    }, sort : {field: "", order: "ASC"}, filter: {filter: record.link}});

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}  resetErrorBoundary={() => {return null;}}/>;
    return (<React.Fragment>
        <Labeled label="Mean and Ci Plot" className={"dashboard-show__label-plot-name"}><FakeFieldToTrickLabelToDisplay source={"not empty"}/></Labeled>
        <Labeled label={"Tank"} className={"dashboard-show__label-plot-name"}>
            <SelectField  source={"tank"}
                         label={"Tank"}
                         choices={Object.values(tanks)} record={realRecord}/>
        </Labeled>
        <Labeled label={"Elongated"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"elongated"}
                         label={"Elongated"}
                         choices={Object.values(elongatedChoices)}
                         record={realRecord}/>
        </Labeled>
        <Labeled label={"Viability"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"viability"}
                         label={"Viability"}
                         choices={Object.values(viabilityChoices)}
                         record={realRecord}/>
        </Labeled>
        <Labeled label={"Morphology"} className={"dashboard-show__label-plot-name"}>
            <SelectField source={"morphology"}
                         label={"Morphology"}
                         choices={Object.values(morphologyChoices)}
                         record={realRecord}/>
        </Labeled>

        <TimeField source={"time"} source2={"days"} index={props.index}/>

    </React.Fragment>);
}

const DashboardDetailShow:FC<{index:number}> = props => {
    const record = useRecordContext();
    const {index} = props;
    const dashboards = record["dashboards"];

    if (dashboards === undefined) {
        return null;
    }
    const realRecord = dashboards[index]
    if (realRecord === undefined) {
        return null
    }
    const plotType = realRecord["plotType"]

    if (plotType === "countproportions") {
        return <CountPlot index={index}/>;
    }
    if (plotType === "meanandci") {
        return <MeanAndCiPlot index={index}/>;
    }
    if (plotType === "elongatedratio") {
        return <ElongatedRatioPlot index={index}/>;
    }


    return (<Error error={{name: "Unexpected exception", message: "Wrong plot type " +plotType}}  resetErrorBoundary={() => {return null;}}/>)
}

export const DashboardConfigShow = (props:ShowProps) => {

    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort: {field: "", order: "ASC"}, filter: {filter:'no_dashboard'}});


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    return (<Show title="Dashboard detail" {...props} >
        <SimpleShowLayout>
            <SelectField source={"id"}
                         label={"Location"}
                         choices={Object.values(locations)}/>
            <DashboardDetailShow index={0}/>
            <DashboardDetailShow index={1}/>
            <DashboardDetailShow index={2}/>
            <DashboardDetailShow index={3}/>

        </SimpleShowLayout>
    </Show>);
}